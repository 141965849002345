<template>
  <div class="shell">
    <div class="container a-container" id="a-container">
      <form action="" method="" class="form" id="a-form">
        <h2 class="form_title title">注册账号</h2>
<!--        <div class="form_icons">
                    <i class="iconfont icon-QQ"></i>
                    <i class="iconfont icon-weixin"></i>
                    <i class="iconfont icon-bilibili-line"></i>
        </div>-->
        <span class="form_span">欢迎注册凌空物语智能科技</span>
        <el-form :model="user" :rules="rules" ref="userForm" class="form">
          <el-form-item prop="username">
            <el-input size="default" prefix-icon="el-icon-user-solid" v-model="user.username" placeholder="请输入用户名" />
          </el-form-item>
          <el-form-item prop="password">
            <el-input size="default" prefix-icon="el-icon-lock"  v-model="user.password" placeholder="请输入密码" type="password"/>
          </el-form-item>
          <el-form-item prop="confirmPassword">
            <el-input size="default" prefix-icon="el-icon-lock"  v-model="user.confirmPassword" placeholder="请再次输入密码" type="password"/>
          </el-form-item>

          <el-form-item prop="email">
            <el-input size="default" prefix-icon="el-icon-message" v-model="user.email" placeholder="请输入邮箱"></el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input size="default" prefix-icon="el-icon-lock" style="width: 120px" v-model="user.code" @keydown.enter.native="register" placeholder="填入验证码"></el-input>
            <el-button type="primary" size="small" class="ml-5" autocomplete="off" @click="showValidCodeDialog">发送验证码</el-button>
          </el-form-item>

          <el-form-item>
            <el-button class="form_button button submit" type="primary"  icon="el-icon-circle-check" @click="register" >确定注册</el-button>
<!--            <el-button type="warning" size="large" @click="$router.push('/login')" round>前往登录</el-button>-->
          </el-form-item>
        </el-form>
<!--        <button class="form_button button submit">注 册</button>-->
      </form>
    </div>

    <div class="container b-container" id="b-container">
      <form action="" method="" class="form" id="a-form">
        <h2 class="form_title title">登录账号</h2>
<!--        <div class="form_icons">
          <i class="iconfont icon-QQ"></i>
          <i class="iconfont icon-weixin"></i>
          <i class="iconfont icon-bilibili-line"></i>
        </div>-->
        <span class="form_span">温馨提示：如果您没有账号，请先注册绑定邮箱，即可使用邮箱登录</span>
<!--        <input type="text" class="form_input" placeholder="Email">-->
        <el-link class="form_link"  @click="handlePass" style="color: #fa2424; font-weight: bold;">忘 记 密 码 ？</el-link>
<!--        <button class="form_button button submit">登 录</button>-->
        <el-tabs type="card" v-model="activeName">
          <el-tab-pane label="账户密码登录" name="first">
            <div style="margin: 1px auto;  width: 320px;  padding: 10px; border-radius: 10px">
              <el-form :model="user" :rules="rules" ref="userForm">
                <el-form-item prop="username">
                  <el-input size="medium" prefix-icon="el-icon-user" v-model="user.username" placeholder="请输入用户名"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input size="medium" prefix-icon="el-icon-lock" show-password v-model="user.password" placeholder="请输入密码"></el-input>
                </el-form-item>
                <el-form-item prop="code">
                  <div class="code-input-container">
                    <el-input size="small" placeholder="请输入验证码" prefix-icon="el-icon-circle-check" v-model="user.code" @keydown.enter.native="loginAccount" />
                    <valid-code @update:value="getCode"/>
                  </div>
                </el-form-item>
                <el-form-item>
<!--                  <el-button type="info" size="medium" icon="el-icon-thumb" @click="$router.push('/register')" round>注册新账号</el-button>-->
                  <el-button class="form_button button submit"  type="primary"  icon="el-icon-circle-check" @click="loginAccount" >立即登录</el-button>
                </el-form-item>
<!--                <el-form-item style="margin: 10px 0; text-align: right">-->
<!--                  <el-button type="text" size="medium" autocomplete="off" @click="handlePass" style="color: #fa2424; font-weight: bold;">忘记密码</el-button>-->
<!--                </el-form-item>-->
              </el-form>
            </div>
          </el-tab-pane>
          <el-tab-pane label="邮箱验证登录" name="second">

              <el-form :model="user" :rules="rules" ref="userForm">
                <el-form-item prop="email">
                  <el-input size="medium" prefix-icon="el-icon-message" v-model="user.email" placeholder="请输入邮箱"></el-input>
                </el-form-item>
                <el-form-item prop="code">
                  <el-input size="small" prefix-icon="el-icon-lock" style="width: 180px" v-model="user.code" @keydown.enter.native="loginEmail" placeholder="请输入验证码"></el-input>
                  <el-button type="primary" size="medium" class="ml-5" autocomplete="off" @click="showValidCodeDialog">发送验证码</el-button>
                </el-form-item>
                <el-form-item class="login-buttons">
<!--                  <el-button type="info" size="medium" icon="el-icon-thumb" @click="$router.push('/register')" round>注册新账号</el-button>-->
                  <el-button class="form_button button submit" type="primary" size="medium" icon="el-icon-circle-check"  @click="loginEmail" round>立即登录</el-button>
                </el-form-item>

<!--                <el-form-item style="margin: 10px 0; text-align: right">-->
<!--                  <el-button type="text" size="medium" autocomplete="off" @click="handlePass" style="color: #fa2424; font-weight: bold;">忘记密码</el-button>-->
<!--                </el-form-item>-->
              </el-form>

          </el-tab-pane>
        </el-tabs>

      </form>
    </div>


    <el-dialog title="安全验证"  :visible.sync="dialogVisible" width="20%"  :before-close="handleClose">
      <div style="display: flex; align-items: center;">
        <el-input v-model="userValidCode" @keydown.enter.native="verifyValidCode" placeholder="请输入验证码" style="margin-right: 10px;"></el-input>
        <ValidCode ref="validCode" @update:value="updateValidCode" style="display: inline-block;"/>
      </div>
      <span slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="verifyValidCode">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="忘记密码" :visible.sync="dialogFormVisible" width="25%">
      <el-form label-width="100px" size="small">
        <el-form-item label="邮箱">
          <el-input v-model="pass.email" placeholder="请输入账号绑定的邮箱" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="验证码">
          <el-input size="small" style="width: 200px" v-model="pass.code" @keydown.enter.native="passwordBack" placeholder="请输入验证码"></el-input>
          <el-button type="primary" size="small" class="ml-5" autocomplete="off" @click="sendEmailCode(2)">发送验证码</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" size="medium" @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" size="medium" @click="passwordBack">重置密码</el-button>
      </div>
    </el-dialog>

    <div class="switch" id="switch-cnt">
      <div class="switch_circle"></div>
      <div class="switch_circle switch_circle-t"></div>
      <div class="switch_container" id="switch-c1">
        <h2 class="switch_title title" style="letter-spacing: 0;">Welcome Back！ </h2>
        <p class="switch_description description">已经有账号了嘛，去登录账号进入奇妙世界！</p>
        <button class="switch_button button switch-btn">立 即 登 录</button>
      </div>

      <div class="switch_container is-hidden"  id="switch-c2">
        <h2 class="switch_title title" style="letter-spacing: 0;">Hello Friend ！ </h2>
        <p class="switch_description description">去注册一个账号吧，让我们踏入奇妙的旅程！</p>
        <button class="switch_button button switch-btn">前 往 注 册</button>
      </div>
    </div>
  </div>
</template>

<script>

import {resetRouter, setRoutes} from "@/router";
import ValidCode from "@/components/ValidCode";
import webSocketService from "@/services/websocketService";

export default {
  name: 'Loginn',
  components: {ValidCode},
  data() {
    // 验证码校验
    // const validateCode = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('请输入验证码'))
    //   } else if (value.toLowerCase() !== this.code1111) {
    //     callback(new Error('验证码输入错误!!'))
    //   } else {
    //     callback()
    //   }
    // }
    return {
      user: {
        username: '',
        email: '',
        password: '',
        confirmPassword: ''
      },
      code: '',
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 5, max: 15, message: '长度在 5 到 15 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 5, max: 15, message: '长度在 5 到 15 个字符', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { validator: this.validateConfirmPassword, trigger: 'blur' }
        ],
        // code11111: [
        //   { validator: validateCode, trigger: 'blur' }
        // ],
      },
      //user: {},
      pass: {},
      activeName: 'first',
      dialogFormVisible: false,
      dialogVisible: false,
      userValidCode: '',
      validCode: '',
    };
  },
  mounted() {
    let switchCtn = document.querySelector("#switch-cnt");
    let switchC1 = document.querySelector("#switch-c1");
    let switchC2 = document.querySelector("#switch-c2");
    let switchCircle = document.querySelectorAll(".switch_circle");
    let switchBtn = document.querySelectorAll(".switch-btn");
    let aContainer = document.querySelector("#a-container");
    let bContainer = document.querySelector("#b-container");
    let allButtons = document.querySelectorAll(".submit");

    let getButtons = (e) => e.preventDefault()
    let changeForm = (e) => {
      //修改类名
      switchCtn.classList.add("is-gx");
      setTimeout(() => {
        switchCtn.classList.remove("is-gx");
      }, 1500)
      switchCtn.classList.toggle("is-txr");
      switchCircle[0].classList.toggle("is-txr");
      switchCircle[1].classList.toggle("is-txl");

      switchC1.classList.toggle("is-hidden");
      switchC2.classList.toggle("is-hidden");
      aContainer.classList.toggle("is-txl");
      bContainer.classList.toggle("is-txl");
      bContainer.classList.toggle("is-z");
    }
    //点击切换
    let shell = (e) => {
      for (var i = 0; i < allButtons.length; i++)
        allButtons[i].addEventListener("click", getButtons);
      for (var i = 0; i < switchBtn.length; i++)
        switchBtn[i].addEventListener("click", changeForm);
    }
    shell();


  },
  methods: {
    sendEmailCode(type) {
      let email;
      if (type === 1) {
        email = this.user.email
      } else if (type === 2) {
        email = this.pass.email
      }else if (type === 3) {
        email = this.user.email
      }
      if (!email){
        this.$message.error("请输入您的邮箱")
        return
      }
      if (!/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(email)) {
        this.$message.error("请输入正确的邮箱")
        return
      }
      this.request.get("/user/email/" + email + "/" + type).then(res => {
        if (res.code === '200') {
          this.$message.success("验证码发送成功")
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    register() {
      this.$refs['userForm'].validate((valid) => {
        if (valid) {
          if (this.user.password !== this.user.confirmPassword) {
            this.$message.error("两次输入的密码不一致");
            return;
          }
          this.request.post("/user/register", this.user).then(res => {
            if (res.code === '200') {
              this.$message.success("注册成功,登录后请尽快完善个人信息");
              setTimeout(() => {
                window.location.reload(); // 延迟刷新页面
              }, 5000);
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },

    validateConfirmPassword(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.user.password) {
        callback(new Error('两次输入的密码不一致'));
      } else {
        callback();
      }
    },

    loginAccount() {
      const validateCode = (value) => {
        if (value === '') {
          return new Error('请输入验证码');
        } else if (value.toLowerCase() !== this.code) {
          return new Error('验证码输入错误!!!');
        } else {
          return null;
        }
      };
      const codeError = validateCode(this.user.code);
      if (codeError) {
        this.$message.error(codeError.message);
        return;
      }
      this.$refs['userForm'].validate((valid) => {
        if (valid) {
          this.request.post("/user/loginAccount", this.user).then(res => {
            if(res.code === '200') {
              // 存储用户信息到浏览器
              localStorage.setItem("user", JSON.stringify(res.data))
              // 存储用户信息到浏览器
              localStorage.setItem("menus", JSON.stringify(res.data.menus))

              // 动态设置当前用户的路由
              setRoutes()
              webSocketService.initWebSocket(); // 初始化 WebSocket
              this.$router.push("/")
              setTimeout(() => {
                window.location.reload(); // 延迟刷新页面
              }, 500);
              this.$message.success("登录成功")
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      });
    },
    getCode(code) {
      this.code = code.toLowerCase()
    },
        loginEmail() {
      if (!this.user.email) {
        this.$message.error("请输入您的邮箱");
        return;
      }
      if (!this.user.code) {
        this.$message.error("请输入您的验证码");
        return;
      }
      this.request.post("/user/loginEmail", this.user).then(res => {
        if (res.code === '200') {
          localStorage.setItem("user", JSON.stringify(res.data));  // 存储用户信息到浏览器
          localStorage.setItem("menus", JSON.stringify(res.data.menus));  // 存储用户信息到浏览器

          // 动态设置当前用户的路由
          setRoutes();
          this.$router.push("/");
          setTimeout(() => {
            window.location.reload(); // 延迟刷新页面
          }, 500);
          this.$message.success("登录成功");
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    handlePass() {
      this.dialogFormVisible = true
      this.pass = {}
    },
    passwordBack() {
      this.request.put("/user/reset", this.pass).then(res => {
        if (res.code === '200') {
          this.$message.success("重置密码成功，新密码为：123456，请尽快修改密码")
          this.dialogFormVisible = false
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    showValidCodeDialog() {
      this.dialogVisible = true;
      this.userValidCode = ''; // 清空上一次输入的内容
      this.$nextTick(() => {
        this.$refs.validCode.refreshCode();
      });
    },
    verifyValidCode() {
      if (this.userValidCode.toLowerCase() === this.validCode.toLowerCase()) {
        this.dialogVisible = false;
        this.sendEmailCode(1);
      } else {
        this.$message.error("验证码错误");
        this.$refs.validCode.refreshCode();
      }
    },
    updateValidCode(value) {
      this.validCode = value;
    },
    handleClose(done) {
      this.dialogVisible = false;
      done();
    }
  }
}
</script>


<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /** 字体无法选中*/
  user-select: none;
}

body {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  background-color: #ecf0f3;
  color: #a0a5a8;
}

.shell {
  position: relative;
  width: 100%;
  min-width: 1000px;
  min-height: 600px;
  height: 600px;
  padding: 25px;
  background-color: #ecf0f3;
  box-shadow: 10px 10px 10px #d1d9e6, -10px -10px 10px #f9f9f9;
  border-radius: 12px;
  overflow: hidden;
}

/** 设置响应式*/
@media (max-width: 1200px) {
  .shell {
    transform: scale(0.7);
  }
}
@media (max-width: 1000px) {
  .shell {
    transform: scale(0.6);
  }
}
@media (max-width: 800px) {
  .shell {
    transform: scale(0.5);
  }
}
@media (max-width: 600px) {
  .shell {
    transform: scale(0.4);
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 600px;
  height: 100%;
  padding: 25px;
  background-color: #ecf0f3;
  transition: 1.25s;
}

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.iconfont{
  margin: 0 5px;
  border: rgba(0,0,0,0.5) 2px solid;
  border-radius: 50%;
  font-size: 25px;
  padding: 3px;
  opacity: 0.5;
  transition: 0.1s;
}
.iconfont:hover{
  opacity: 1;
  transition: 0.15s;
  cursor: pointer;
}
.form_title{
  width: 350px;
  height: 40px;
  margin: 4px 0;
  padding-left: 25px;
  font-size: 13px;
  letter-spacing: 0.15px;
  border: none;
  outline: none;
  background-color: #ecf0f3;
  transition: 0.25s ease;
  border-radius: 8px;
  box-shadow: inset 2px 2px 4px #d1d9e6, inset -2px -2px 4px #f9f9f9;
}
.form_input:focus{
  box-shadow: inset 4px 4px 4px #d1d9e6, inset -4px -4px 4px #f9f9f9;
}
.form_span{
  margin-top: 50px;
  margin-bottom: 2px;
  color: #000000;
}
.form_link{
  color: #181818;
  font-size: 15px;
  margin-top: 15px;
  border-bottom: 1px solid #a0a5a8;
  line-height: 2;
}
.title{
  font-size: 34px;
  font-weight: 700;
  line-height: 3;
  color: #181818;
  letter-spacing: 10px;
}
.description{
  font-size: 14px;
  letter-spacing: 0.25px;
  text-align: center;
  line-height: 1.6;
}
.button{
  width: 180px;
  height: 50px;
  border-radius: 25px;
  margin-top: 10px;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1.15px;
  background-color: #4B70E2;
  color: #f9f9f9;
  box-shadow: 8px 8px 16px #d1d9e6, -8px -8px 16px #f9f9f9;
  border: none;
  outline: none;
}
.a-container{
  z-index: 100;
  left: calc(100% - 600px);

}
.b-container{
  left: calc(100% - 600px);
  z-index: 0;
}
.switch{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 400px;
  height: 100%;
  padding: 50px;
  z-index: 200;
  transition: 1.25s;
  background-color: #ecf0f3;
  overflow: hidden;
  box-shadow: 4px 4px 10px #d1d9e6, -4px -4px 10px #d1d9e6;
}
.switch_circle{
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background-color: #ecf0f3;
  box-shadow: inset 8px 8px 12px #b8bec7, inset -8px -8px 12px #fff;
  bottom: -60%;
  left: -60%;
  transition: 1.25s;
}
.switch_circle-t{
  top: -30%;
  left: -60%;
  width: 300px;
  height: 300px;
}
.switch_container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  width: 400px;
  padding: 50px 55px;
  transition: 1.25s;
}
.switch_button{
  cursor: pointer;
}
.switch_button:hover,.submit:hover{
  box-shadow: 6px 6px 10px #d1d9e6, -6px -6px 10px #f9f9f9;
  transform: scale(0.985);
  transition: 0.25s;
}
.switch_button:active,.switch_button:focus{
  box-shadow: 2px 2px 6px #d1d9e6, -2px -2px 6px #f9f9f9;
  transform: scale(0.97);
  transition: 0.25s;
}
.is-txr{
  left: calc(100% - 400px);
  transition: 1.25s;
  transform-origin: left;
}
.is-txl{
  left: 0;
  transition: 1.25s;
  transform-origin: right;
}
.is-z{
  z-index: 200;
  transition: 1.25s;
}
.is-hidden{
  visibility: hidden;
  opacity: 0;
  position: absolute;
  transition: 1.25s;
}
.is-gx{
  animation: is-gx 1.25s;
}
@keyframes is-gx{
  0%,
  10%,
  100%{width: 400px;}
  30%,
  50%{width: 500px}
}



/* 新增验证码输入框和验证码生成器的容器样式 */
.code-input-container {
  display: flex;
  align-items: center; /* 垂直居中对齐 */
}
</style>

<template>
  <div class="ValidCodeContainer" @click="refreshCode">
    <div class="ValidCode disabled-select" :style="{ width: `${codeWidth}px`, height: `${codeHeight}px` }">
      <span v-for="(item, index) in codeList" :key="index" :style="getStyle(item)">{{item.code}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'validCode',
  props: {
    length: {
      type: Number,
      default: 4
    },
    refreshTime: {
      type: Number,
      default: 60000
    }
  },
  data () {
    return {
      codeList: [],
      refreshInterval: null,
      chars: 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz123456789',
      codeWidth: 150,
      codeHeight: 50
    }
  },
  computed: {
    styles() {
      return this.codeList.map(item => ({
        color: item.color,
        fontSize: '18px',
        padding: item.padding,
        transform: item.transform
      }))
    }
  },
  mounted() {
    this.createdCode();
    this.startAutoRefresh();
  },
  beforeDestroy() {
    this.stopAutoRefresh();
  },
  methods: {
    refreshCode () {
      this.createdCode()
    },
    createdCode () {
      const codeList = [];
      for (let i = 0; i < this.length; i++) {
        const charIndex = Math.floor(Math.random() * this.chars.length);
        const char = this.chars[charIndex];
        const style = this.generateStyle();
        codeList.push({ code: char, ...style });
      }
      this.codeList = codeList;
      this.$emit('update:value', codeList.map(item => item.code).join(''));
    },
    generateStyle() {
      const rgb = [Math.round(Math.random() * 220), Math.round(Math.random() * 240), Math.round(Math.random() * 200)];
      const padding = `${Math.floor(Math.random() * 10)}px`;
      const transform = `rotate(${Math.floor(Math.random() * 90) - Math.floor(Math.random() * 90)}deg)`;
      return { color: `rgb(${rgb.join(',')})`, padding, transform };
    },
    getStyle(item) {
      return {
        color: item.color,
        fontSize: '20px',
        padding: item.padding,
        transform: item.transform,
        transition: 'transform 0.5s ease-in-out'
      };
    },
    startAutoRefresh() {
      this.refreshInterval = setInterval(() => {
        this.refreshCode();
      }, this.refreshTime);
    },
    stopAutoRefresh() {
      if (this.refreshInterval) {
        clearInterval(this.refreshInterval);
        this.refreshInterval = null;
      }
    }
  }
}
</script>

<style>
.ValidCode{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.ValidCode span {
  display: inline-block;
}
</style>
